<template>
  <div>
    <div class="container" v-if="device === 'mobile'">
      <PriceOne
        v-for="(item, index) in roomsList"
        :key="index"
        :data="item"
        :index="index"
        :supplier="supplier"
        :hotels="totalHotelList[index]"
        @changedRoomType="triggerChangeRoomType"
        @closed="removeItem"
        @update="updateItem"
        @setPriceLoading="getPriceLoading"
      />
      <div class="chooseroom_footer">
        <div class="chooseroom_footer_top d-flex justify-content-between">
          <a href="#" onclick="return false;" @click="addRoom" class="btn btn-link" :disable="priceLoading">
            <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
            <i class="fas fa-plus" v-else></i> {{ $t("product-page.add-room") }}
          </a>
          <div class="pricebox">
            <!-- {{ $t("product-page.total-per-passenger")}}
            <strong>${{ averagePrice }}</strong> -->
            {{ $t("product-page.total-pay") }}
            <strong>${{ totalPrice }}</strong>
            <s>${{ (totalPrice * 1.1).toFixed(0) }}</s>
          </div>
        </div>
        <button href="#" @click="gotoBook" :disabled="priceLoading" class="btn btn-primary d-block w-100 mt-3">
          {{ $t("product-page.order") }}
        </button>
      </div>
    </div>

    <div class="vacationprice_area" v-if="device === 'desktop'">
      <h3>
        {{ $t("product-page.prices") }}
      </h3>
      <div class="flight-details">
        <div class="hldr">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4 :style="(lang !== 'he')?'direction: ltr;':''"><!--{{ personString }},--> <span dir="ltr">{{ daysString }} <i class="far fa-calendar-alt"></i></span></h4>
                <div class="card cardone">
                  <PriceOne
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :data="item"
                    :index="index"
                    :supplier="supplier"
                    :hotels="totalHotelList[index]"
                    @changedRoomType="triggerChangeRoomType"
                    @closed="removeItem"
                    @update="updateItem"
                    @setPriceLoading="getPriceLoading"
                  />
                  <button type="button" class="add-field" @click="addRoom" :disabled="addRoomDisable || priceLoading">
                    <i class="far fa-plus-square"></i>
                    <b-spinner small type="grow" v-if="priceLoading"></b-spinner>{{ $t("product-page.add-room") }}<i class="fas fa-hospital-alt"></i>
                  </button>
                </div>
                <h4 class="pt-2" v-if="adminRemark">{{ $t("product-page.remarks")}}: {{adminRemark}}</h4>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="flight-details-leftbox">
                <h4>{{ $t("product-page.vacation-price") }}</h4>
                <div class="card cardtwo">
                  <PriceTwo
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :room="item"
                    :index="index"
                    @closed="removeItem"
                  />
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <div class="p-2">{{ $t("product-page.special-price") }}</div>

                      <div v-if="isOdysseySite && !isMEMSHAKAgent">
                        <div class="p-2">{{ $t('search-result.net-price') }}: <span>${{ netPrice }}</span></div>
                      </div>
                      <div class="p-2">${{ totalPrice }}</div>
                    </div>
                  </div>
                </div>
                <div class="addfooterbox d-flex justify-content-between">
                  <!-- <p><img src="assets/img/breach.jpg" alt="" /></p> -->
                  <div v-if="isOdysseySite && !isMEMSHAKAgent">
                    <!-- <p class="p-3" v-if="supplier === 'ATLANTIS'"><span>{{ $t('search-result.commission') }}: </span>5%</p>
                    <p class="p-3" v-else><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p> -->
                    <p class="p-3"><span>{{ $t('search-result.commission') }}: </span>{{ `${commission}%` }}</p>
                  </div>
                  <p v-else></p>
                  <button class="btn btn-primary" :disabled="priceLoading" @click="gotoBook">
                    {{ $t("product-page.book-button") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { BSpinner } from 'bootstrap-vue';
import { toBase64 } from '@/utils/base64Util';
import googleAnalytics from '@/mixins/googleAnalytics';

export default {
  components: {
    BSpinner,
    PriceOne: () => import('@/components/productPage/PriceOne'),
    PriceTwo: () => import('@/components/productPage/PriceTwo'),
  },
  props: {
    hotels: {
      type: Array,
      default: () => [],
    },
    moreHotelInfo: {
      type: Object,
      default: null,
    },
    searchGuid: {
      type: String,
      default: '',
    },
    supplier: {
      type: String,
      default: '',
    },
    hotelName: {
      type: String,
      default: '',
    },
    destinationCityName: {
      type: String,
      default: '',
    },
  },
  mixins: [googleAnalytics],
  data() {
    return {
      searchQuery: '',
      query: null,

      personString: '',
      daysString: '',

      roomsList: [],
      maxRoomCount: 5,
      addRoomDisable: false,
      priceLoading: false,

      adult: 0,
      child: 0,
      infant: 0,
      totalPrice: 0,
      oldTotalPrice: 0,
      netPrice: 0,
      averagePrice: 0,
      translations: [],

      totalHotelList: [],
      product: null,

      supplierList: process.env.VUE_APP_SUPPLIER_LIST.split(','),
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      // isLogin: 'IS_AUTHORIZED',
      // memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
      hotelList: 'GET_CAMINGO_HOTELS',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      agentInfo: 'GET_LOGIN_AGENT_INFO',
      bigAgentInfo: 'GET_BIG_AGENT_INFO',
    }),
    adminRemark() {
      return this.supplier === 'ATLANTIS' ? this.hotels[0].remark : this.hotels[0].adminRemark;
    },
    commission() {
      const { hotelList, agentInfo, isMEMSHAKAgent, bigAgentInfo, isOdysseySite } = this;
      if (!hotelList.data || !agentInfo) return 0;
      // if (this.supplier === 'ATLANTIS') return 3;
      const hotel = hotelList.data.find((ht) => (ht.hotelExternalCode === this.$route.query.hotelCode) || (this.supplier === 'ATLANTIS' && ht.hotelCamingoCode === this.$route.query.hotelCode));
      if (isMEMSHAKAgent) return hotel.commission;
      // return agentInfo && this.supplier === 'ATLANTIS' ? agentInfo.commissionForFcHotels || agentInfo.commission : agentInfo?.commission || hotel.commission;
      let comm = agentInfo && this.supplier === 'ATLANTIS' ? agentInfo.commissionForFcHotels || agentInfo.commission : agentInfo?.commission || hotel.commission;
      if (isOdysseySite) {
        const hotelComm = hotel?.commission || 0;
        const matchedRule = bigAgentInfo?.find((item) => item.fcCommPct === hotelComm && ((this.supplier === 'ATLANTIS') === item.forFcHotel));
        const bigComm = matchedRule?.bigAgentCommPct || 0;
        const littleCom = matchedRule?.littleAgentCommPct || 0;
        comm = ((agentInfo.bigAgent) ? bigComm : littleCom) || comm;
      }
      return comm;
    },
  },
  watch: {
    roomsList() {
      this.addRoomDisable = this.roomsList.length === this.maxRoomCount;
      this.updateTotalPrice();
    },
    commission() {
      this.updateTotalPrice();
    },
    lang() {
      this.personString = this.getPersonString(this.adult, this.child, this.infant);
    },
  },
  beforeMount() {
    this.query = this.$route.query;
    this.adult = Number(this.query.adult);
    this.child = Number(this.query.child);
    this.infant = Number(this.query.infant);

    this.personString = this.getPersonString(this.adult, this.child, this.infant);

    this.daysString = `${dayjs(this.query.checkIn).format('D.M')} - ${dayjs(this.query.checkOut).format('D.M')}`;

    this.totalHotelList.push(this.hotels);

    this.roomsList.push(this.getOneItem());
  },
  methods: {
    triggerChangeRoomType(inx, packages) {
      const hotels = [];
      if (packages && packages.length > 0) {
        packages.forEach((item) => {
          item.data.data.forEach((elm) => {
            elm.supplierCode = item.supplierCode;
            elm.adminRemark = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].adminRemark : '';
            elm.imgUrl = (item.hotelInfo[elm.exCode]) ? item.hotelInfo[elm.exCode].imageUrl : '';
            elm.searchGuid = item.searchGuid;
            hotels.push(elm);
          });
        });
      }
      this.totalHotelList[inx] = hotels;
    },
    getPersonString(adult, child, infant) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    removeItem(index) {
      this.roomsList.splice(index, 1);
      this.totalHotelList.splice(index, 1);
    },
    addRoom() {
      if (!this.addRoomDisable) {
        this.roomsList.push(this.getOneItem());
        this.totalHotelList.push(this.hotels);
      }
    },
    async updateItem(priceInfo) {
      for (const key in this.roomsList[priceInfo.index]) {
        this.roomsList[priceInfo.index][key] = priceInfo[key];
      }
      this.averagePrice = priceInfo.price;
      this.updateTotalPrice();
    },
    getOneItem() {
      return {
        roomRateGuid: '',
        searchGuid: '',
        roomClass: '',
        roomClassName: '',
        roomBasis: '',
        roomBasisName: '',
        adult: 0,
        child: 0,
        infant: 0,
        price: 0,
        oldPrice: 0,
        token: '',
        discountPercent: 0,
        priceList: '',
        roomOccupCode: '',
        roomOccupString: '',
        roomTypeName: '',
        category: null,
        validation: false,
        net: 0,
        currencyCode: 'US',
        totalInOrgCur: 0,
      };
    },
    updateTotalPrice() {
      // const { memberDiscountPercent } = this;
      this.totalPrice = this.roomsList.reduce((a, b) => +a + +b.price, 0);
      // this.oldTotalPrice = this.roomsList.reduce((a, b) => +a + +b.oldPrice, 0);
      this.netPrice = Math.round(this.totalPrice * (1 - this.commission / 100));
      // const oPrice = this.roomsList.reduce((a, b) => +a + +b.price, 0);

      // const isAtlantis = this.supplier === 'ATLANTIS';
      // const isCreditCardRemarkHotel = false;
      // const price = (isAtlantis || isCreditCardRemarkHotel) ? oPrice : oPrice * (1 - memberDiscountPercent / 100);
      // this.totalPrice = (this.isLogin) ? Math.round(price) : Math.round(oPrice);

      // this.product.priceTotal = this.totalPrice;
      // window.localStorage.setItem('production', JSON.stringify(this.product));
      // this.$store.dispatch('UPDATE_ROOM_LIST', { roomList: this.roomsList });
    },
    getPriceLoading(isLoading) {
      this.priceLoading = isLoading;
    },
    async gotoBook() {
      const suplierCodeList = process.env.VUE_APP_SUPPLIER_LIST.split(',');
      const dealId = this.query.dealId || '';
      const rooms = [];
      if (this.roomsList.filter((item) => !item.validation).length > 0) {
        this.$bvModal.msgBoxOk(
          this.$t('product-page.no-hotel-message'),
          {
            title: this.$t('product-page.expire-title'),
            bodyClass: 'text-center',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      this.roomsList.forEach((room) => {
        const roomData = {
          searchGuid: room.searchGuid,
          roomRateGuid: room.roomRateGuid,
          bookingToken: room.token,
          totalAfterDiscount: room.price,
          discountPercent: room.discountPercent,
          totalOriginal: room.totalInOrgCur, // room.oldPrice,
          totalInOrgCur: room.totalInOrgCur,
          currencyCode: room.currencyCode,
          roomCode: room.roomClass,
          basisCode: room.roomBasis,
          priceListCode: room.priceList,
          adults: Number(room.adult),
          children: Number(room.child),
          infant: Number(room.infant),
          roomOccupancyCode: room.roomOccupCode,
          roomOccupString: room.roomOccupString,
          roomTypeName: room.roomTypeName,
          roomClassName: room.roomClassName,
          roomBasisName: room.roomBasisName,
          category: room.category,
          nett: room.net,
          paxIds: [],
        };
        rooms.push(roomData);
      });

      this.product = {
        agentScreenResolultion: this.device,
        grandTotalAfterDiscount: this.totalPrice,
        netPrice: this.netPrice,
        priceBeforeCoupon: this.totalPrice,
        couponCode: '',
        currencyCode: 'US',
        hotel: {
          source: suplierCodeList.indexOf(this.supplier), // this.moreHotelInfo.hotelConversion.source,
          hotelCode: this.query.hotelCode,
          cityName: this.destinationCityName,
          checkinDate: this.query.checkIn,
          checkoutDate: this.query.checkOut,
          remarkInVoucher: this.adminRemark,
          hotelName: this.hotelName,
          cityCode: this.query.city,
          hotelSupplierCode: this.supplier,
          rooms,
        },
        paxList: [],
        payer: null,
        dealId,
      };

      const data = {
        data: this.product,
        sendingStage: 'init',
      };

      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);
      this.product.moreHotelInfo = this.moreHotelInfo;
      window.localStorage.setItem('production', toBase64(JSON.stringify(this.product)));

      // if (!window.location.host.includes('vercel')) {
      //   this.gtag('event', 'view_item', {
      //     items: [
      //       {
      //         id: response.id, // please assign number
      //         name: `${this.hotelName} - ${this.destinationCityName}`,
      //         list_name: '',
      //         brand: '',
      //         category: '',
      //         variant: '',
      //         list_position: 1,
      //         quantity: 1,
      //         price: this.totalPrice,
      //       },
      //     ],
      //   });
      // }

      if (response.success) {
        window.localStorage.setItem('BookingDataID', response.id);
        window.localStorage.setItem('retryState', 'null');

        const urlBookingInfoPage = dealId ? `/booking/hotel/?dealId=${dealId}` : '/booking/hotel';
        let body = {};
        const { marketerId, subMarketerId } = this;
        if (marketerId && marketerId !== '') body = { marketerId };
        if (subMarketerId && subMarketerId !== '') body = { ...body, subMarketerId };
        this.$router.push({ path: urlBookingInfoPage, query: body });
      } else {
        window.localStorage.setItem('BookingDataID', '');
      }
    },
  },
};
</script>
<style scoped>
button:disabled,
[type="button"]:disabled,
[type="reset"]:disabled,
[type="submit"]:disabled {
  cursor: default;
  opacity: 0.2;
}
</style>
<style>
button.js-del-row {
  top: -5px;
}

@media (max-width: 600px) {
  div[id^="upgrade-"] .modal-dialog {
    width: 96%;
  }
  div[id^="upgrade-"] table th,
  div[id^="upgrade-"] table td {
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .flightpagelist.filterItem {
    margin-top: 1em;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 5px;
  }
}
</style>
